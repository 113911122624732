body {
    background-color: #e5e5e5 !important;
}

a {
    color: white
}

button:disabled,
button[disabled] {
    border             : 1px solid #999999 !important;
    /* background-color: #cccccc; */
    color              : #666666 !important;
}

.customButton {
    color       : #9a3412 !important;
    /* Text color */
    border-color: #9a3412 !important;
    /* Border color */
}

.customButton:hover {
    border-color    : #9a3412 !important;
    /* Border color on hover */
    background-color: rgba(154, 52, 18, 0.1) !important;
    /* Optional background color on hover */
}

.customContainedButton {
    color           : white !important;
    /* Text color */
    border-color    : #9a3412 !important;
    /* Border color */
    background-color: #9a3412 !important;
}

.customContainedButton:hover {
    border-color    : #9a3412 !important;
    /* Border color on hover */
    background-color: #882b0d !important;
    /* Optional background color on hover */
}